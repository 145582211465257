var hooxSliderUGC = document.querySelector(".hoox-swiper-ugc");
var hooxSliderUGCLength = hooxSliderUGC.querySelectorAll(".swiper-slide")?.length;
var hooxSliderUGCNavigation = hooxSliderUGC.querySelector(".hoox-swiper-ugc__navigation");
var isDesktop = window.innerWidth > 1023;

if (hooxSliderUGC) {
  var slider = new Swiper(hooxSliderUGC, {
    slidesPerView: 1,
    loop: true,
    draggable: false,
    watchOverflow: true,
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 64,
      },
    },
    pagination: {
      el: '.hoox-swiper-ugc__pagination',
      clickable: true,
    },
    navigation: {
      nextEl: ".slider-ugc-next",
      prevEl: ".slider-ugc-prev",
    },
  });
}

if (hooxSliderUGCNavigation) {
  if (
    isDesktop && hooxSliderUGCLength > 4 ||
    !isDesktop && hooxSliderUGCLength > 1
  ) {
    hooxSliderUGCNavigation.classList.add('visible');
  }
}

if(isDesktop && hooxSliderUGCLength <= 4) {
    slider.draggable = false;
    slider.allowTouchMove = false; 
}